@import "@styles/variables.scss";

$top-margin: 15px;

.select {
	display: flex;
	flex-direction: column;
	width: 100%;

	label {
		margin-bottom: 0;
	}

	.rc-select {
		display: inline-block;
		width: 100%;
		height: 100%;
		margin-bottom: 0;
	}

	.rc-select-selector {
		font-family: "Manrope", sans-serif;
		border: 1px solid #d0d7e5 !important;
		border-radius: 5px;
		width: 326px;
		height: 55px;
		margin-bottom: 0;

		color: $color-dark !important;
		font-size: $base-font;
		padding-left: 17px;
		box-sizing: border-box;

		transition: border-color .25s ease;

		&:focus {
			outline: none;
			border-color: $color-blue;
		}

		@media (max-width: 838px) {
			width: 100%;
			height: 36px;
		}
	}

	.rc-select-selection-item {
		top: $top-margin !important;
		left: 13px !important;

		text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    max-width: 70%;
    overflow: hidden;
	}

	.rc-select-selection-search-input {
		height: 100%;
		padding: 10px;

		background: none !important;
		font-family: "Manrope", sans-serif;
		color: $color-dark !important;
		font-size: $base-font;
	}

	.rc-select-clear {
		top: $top-margin;
		right: 45px;
		cursor: pointer;

		.rc-select-clear-icon {
			font-size: 24px;
		}
	}

	.rc-select-show-arrow .rc-select-arrow {
		top: 17px;
		right: 20px;
		height: 20px;
		transition: transform 0.5s ease;
	}

	.rc-select-open {
		.rc-select-arrow {
			transform: rotate(180deg);
		}
	}

	@media (max-width: 838px) {
		.rc-select-selection-search-input {
			padding: 1px 1px 1px 17px;
		}

		.rc-select-selection-item {
			top: 5px !important;
			left: 18px !important;
		}

		.rc-select-show-arrow .rc-select-arrow {
			top: 8px;
			right: 15px;
		}

		.rc-select-clear {
			top: 5px;
			right: 35px;
		}
	}

	@media (max-width: 450px) {
		margin-bottom: 19px;

		.rc-select-selection-search-input {
			padding: 1px 1px 1px 17px;
		}

		.rc-select-selection-item {
			top: 5px !important;
		}
	}
}

.rc-select-disabled > span {
	opacity: .3;
}

.rc-select-dropdown {
	border-radius: 5px;
	border: none !important;
	border-color: none !important;
	box-shadow: 0 2px 8px rgba(0, 0, 0, .15);

	.rc-select-item-option-state {
		display: none;
	}

	.rc-select-item-option-active {
		background: $color-light !important;
	}
}
