@import "@styles/variables.scss";

.footer {
	width: 100%;
	background: $color-dark;
	font-weight: 400;
	font-size: 20px;
	line-height: 36px;
	color: $color-white;

	display: flex;
	align-items: center;
	height: 111px;

	&__caption {
		padding-left: $base-margin;
	}

	&__link {
		color: $color-blue;
		margin-left: 10px;
	}

	@media (max-width: 768px) {
		height: 70px;
		font-size: $base-font;

		&__caption {
			padding-left: 28px;
		}
	}

	@media (max-width: 440px) {
		font-size: $xxs-font;

		&__caption {
			padding-left: 16px;
		}
	}
}
