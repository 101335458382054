@import "@styles/variables.scss";

.about {
	width: 100%;
	background: #f3f6fc;

	&__text {
		width: 513px;
		padding-top: 129px;
		margin: 0 $base-margin 67px;
	}

	&__title {
		margin-bottom: 10px;
	}

	&__paragraph:first-of-type {
		margin-bottom: 30px;
	}

	&__photos {
		display: grid;
		grid-template-columns: repeat(2, .4fr);
		grid-template-rows: repeat(1, 1fr);
		margin: 0 $base-margin;
		padding: 50px 0 100px;
		justify-content: space-around;
	}

	&__photo {
		width: 100%;
		aspect-ratio: 1.09;
		object-fit: cover;
		object-position: center 15px;
	}

	&__photo_block {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin: 0;
		gap: 5px;
	}

	@media (max-width: $width-laptop--lg) {
		&__text {
			width: unset;
			padding-top: 820px;
			margin: 0 $base-margin 30px;
		}

		&__paragraph,
		&__paragraph:first-of-type {
			margin-bottom: 0;
		}

		&__description {
			display: flex;
			column-gap: 30px;
		}

		&__photos {
			column-gap: 30px;
			justify-content: unset;
			grid-template-columns: repeat(2, 1fr);
			padding: 0 0 100px;
		}
	}

	@media (max-width: $width-tablet) {
		&__text {
			padding-top: 620px;
		}

		&__description {
			flex-direction: column;
			row-gap: 20px;
		}
	}

	@media (max-width: 700px) {
		&__text {
			padding-top: 720px;
		}

		&__photos {
			width: $base-width;
			margin: 0 auto;
			padding-bottom: 51px;
			column-gap: 0;
			row-gap: 23px;
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: repeat(2, 1fr);
		}
	}

	@media (max-width: $width-mobile--md) {
		&__text {
			padding-top: 820px;
		}
	}
}
