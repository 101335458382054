@import "@styles/variables.scss";

.demo {
	width: 835px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #fff;
	border-radius: 10px;
	text-align: center;
	font-weight: 400;
	font-size: $base-font;
	line-height: 25px;
	color: $color-grey;

	position: absolute;
	top: 95px;
	right: 10%;
	left: 4$base-margin;

	form {
		width: 100%;
		justify-items: start;
	}

	&__title {
		width: 100%;
		border-bottom: 1px solid #d0d7e6;
		color: $color-dark;
		padding: 24px 0 28px;
	}

	&__form {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 27px;
		margin: 37px auto;
		justify-items: start;
		text-align: left;

		label {
			margin-bottom: 19px;
		}

		div {
			margin-bottom: 19px;
		}
	}

	&__fields {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: flex-start;
		margin-bottom: 19px;
		text-align: start;

		label {
			margin-bottom: 0;
		}

		div {
			margin-bottom: 0;
		}
	}

	&__field {
		font-family: "Manrope", sans-serif;
		border: 1px solid #d0d7e5;
		border-radius: 5px;
		width: 326px;
		height: 55px;

		color: $color-dark !important;
		font-size: $base-font;
		padding-left: 17px;
		box-sizing: border-box;

		transition: border-color .25s ease;
	}

	&__field:focus {
		outline: none;
		border-color: $color-blue;
	}

	&__field_error {
		border-color: $color-red;
	}

	&__row {
		grid-column: 1 / -1;
		flex-direction: row;
		align-items: center;

		label {
			width: 356px;
			text-align: left;
		}
	}

	&__row_field {
		width: 86px;
		box-sizing: border-box;
	}

	&__radio {
		grid-column: 1 / -1;
		display: flex;
		margin-bottom: 34px;

		p {
			margin-right: 35px;
		}

		div > label > input {
			margin: 0 9px 0 26px;
			appearance: none;
			border-radius: 50%;
			width: 16px;
			height: 16px;

			border: 1px solid #999;
			position: relative;

			&::before {
				content: "";
				opacity: 0;

				transition: opacity .5s ease;
			}
		}

		div > label > input:checked {
			border: 1px solid $color-dark;

			&::before {
				content: "";
				width: 10px;
				height: 10px;
				display: block;
				border-radius: 50%;
				background: $color-red;
				position: absolute;
				top: 2px;
				left: 2px;
				opacity: 1;
			}
		}
	}

	&__notes {
		grid-column: 1/-1;

		div {
			width: 100%;

			textarea {
				max-width: 100%;
				width: 100%;
				font-family: "Manrope", sans-serif;
			}
		}
	}

	@media (max-width: $width-laptop--lg) {
		top: 55%;
		left: 50%;
		transform: translateX(-50%);
	}

	@media (max-width: $width-tablet) {
		width: $base-width;
		margin: 0 auto;

		&__title {
			padding: 13px 0 12px;
		}

		&__fields {
			width: 100%;
		}

		&__field {
			width: 87%;
			height: 36px;
		}

		&__row {
			label {
				width: 60%;
			}

			div > input {
				margin-left: 0;
			}
		}

		&__row_field {
			width: 86px;
		}

		form {
			width: 95%;
		}

		&__row > label {
			width: 55%;
			margin-right: 25px;
		}

	}

	@media (max-width: 600px) {
		top: 50%;

		&__radio {
			flex-direction: column;
			align-items: flex-start;

			p {
				margin-right: 0;
				margin-bottom: 10px;
			}

			div > label:first-of-type > input {
				margin: 0 9px 0 0;
			}

			div > label > input {
				margin: 0 5px 0 9px;
			}
		}
	}

	@media (max-width: $width-mobile--md) {
		width: 87%;
		font-size: $xxs-font;
		line-height: 17.76px;
		margin: 0 auto;

		form {
			width: 100%;
		}

		&__title {
			padding: 13px 0 12px;
		}

		&__form {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			column-gap: 10px;
			margin-top: 27px;

			label {
				margin-bottom: 5px;
			}
		}

		&__fields {

			label {
				margin-bottom: 5px;
			}
		}

		&__field {
			width: 87%;
			height: 36px;
		}

		&__row_field {
			width: 86px;
			margin-left: 0;
		}

		&__row {
			flex-direction: column;
			align-items: flex-start;

			label {
				width: 87%;
			}
		}
	}

	.submit-block {
		width: 100%;
	}
}

.form {
	width: 83%;
}

.submit-block {
	border-top: 1px solid #d0d7e6;
}

.field {
	width: 100%;

	input {
		width: 100%;
	}
}