@import "@styles/variables.scss";

body {
	font-family: $font-family-primary;
	font-size: $base-font;
	font-weight: normal;
	color: $color-dark;
	height: 100%;
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
	margin: 0;
	padding: 0;
}

.app {
	max-width: 1920px;
	margin: 0 auto;
	position: relative;
}

.demo-btn {
	background: $color-red;
	border-radius: 50px;
	color: $color-white;
	border: none;
	font-weight: 600;
	width: 240px;
	height: 60px;
	padding: 0;
	font-size: 20px;
	cursor: pointer;

	@media (max-width: 539px) {
		font-weight: 600;
		width: 163px;
		height: 41px;
		font-size: $xs-font;
	}
}

.text {
	font-size: $base-font;
	font-weight: normal;
	line-height: 32px;

	@media (max-width: 768px) {
		font-size: $sm-font;
		line-height: 29px;
	}

	@media (max-width: $width-mobile) {
		font-size: $xs-font;
		line-height: 27px;
	}
}

.block-title {
	font-size: $small-title-font;
	font-weight: normal;
	line-height: 66px;

	@media (max-width: 768px) {
		font-size: $xlarge-font;
		line-height: 49px;
	}

	@media (max-width: $width-mobile) {
		font-size: $medium-font;
		line-height: 30px;
	}
}

.block-subtitle {
	font-size: $large-font;
	font-weight: 600;
	line-height: 38px;

	@media (max-width: 768px) {
		font-size: $medium-font;
		line-height: 30px;
	}

	@media (max-width: $width-mobile) {
		font-size: $base-font;
		line-height: 25px;
	}
}

.error {
	font-size: $xs-font;
	color: $color-red;
	text-align: left;

	@media (max-width: 500px) {
		font-size: $xxs-font;
	}
}

.error-radio {
	margin-left: 20px;
}


.popup {
	position: fixed;
	bottom: 30px;
	right: 21px;
	padding: 30px;
	background: $color-white;
	border-radius: 10px;
	color: $color-dark;
	display: none;
	width: 300px;
	text-align: left;
	box-shadow: 5px 4px 10px 2px rgba(34, 60, 80, 0.2);

	&_opened {
		display: flex;
		align-items: flex-start;
	}

	&__message {
		margin-left: 10px;
	}

	@media (max-width: 400px) {
		width: 250px;
		padding: 20px;
		right: 15px;
	}
}

.demo__submit {
	margin: 34px auto 39px;
	transition: background-color .5s ease;
	display: flex;
	align-items: center;
	justify-content: center;

	&_success {
		background: #3cc166;
		cursor: auto;
	}

	.demo__loader {
		width: 40px;
		display: none;

		&_visible {
			display: block;
		}
	}

	@media (max-width: 539px) {
		.demo__loader {
			width: 30px;
		}
	}

	@media (max-width: 450px) {
		margin: 21px auto 22px;
	}
}