@import "@styles/variables.scss";

a {
	text-decoration: none;
}

.container {
	width: $base-width;
	margin: 0 auto;
}

.header {
	width: 100%;
	min-height: 94px;
	background-color: $color-white;
	position: sticky;
	top: 0;
	z-index: 100000;

	@media (max-width: $width-mobile--lg) {
		min-height: 54px;
	}

	&__row {
		width: 100%;
		display: flex;
		justify-content: space-between;
		min-height: 47px;
		align-items: center;
		padding: 10px 0;

		@media (max-width: 767px) {
			margin: 0 20px;
		}

		@media (max-width: $width-mobile--lg) {
			margin: 0 auto;
		}
	}

	&__logo {
		@media (min-width: 1089px) {
			width: 154px;
		}

		@media (max-width: $width-mobile--lg) {
			width: 98px;
			height: 34px;
		}
	}

	&__menu {
		color: $color-dark;
		display: flex;
		align-items: center;

		@media (max-width: 1089px) {
			flex-direction: row-reverse;
		}
	}

	&__btn {
		background: #ebf1fc;
		border-radius: 50px;
		border: none;
		width: 148px;
		height: 55px;
		font-size: $medium-font;
		line-height: 30px;
		cursor: pointer;
		padding: 0;
		margin-left: 77px;

		a {
			border-radius: 50px;
			border: 1px solid $color-blue;
			color: $color-dark;
			padding: 8px 38px;
		}

		@media (max-width: 1089px) {
			margin-left: 0;
			margin-right: 28px;
		}

		@media (max-width: $width-mobile--lg) {
			font-size: $xxs-font;
			width: 91px;
			height: 34px;

			a {
				padding: 4px 20px;
			}
		}
	}
}

.menu {
	&__icon {
		display: none;

		@media (max-width: 1089px) {
			z-index: 5;
			display: block;
			position: relative;
			width: 40px;
			height: 25px;
			cursor: pointer;
		}

		&::after,
		&::before,
		span {
			left: 0;
			position: absolute;
			height: 10%;
			width: 100%;
			transition: all .3s ease 0s;
			background-color: $color-dark;
			border-radius: 50px;
		}

		&::after,
		&::before {
			content: "";
		}

		&::before {
			top: 0;
		}

		&::after {
			bottom: 0;
		}

		span {
			top: 50%;
			transform: scale(1) translate(0, -50%);
		}
	}

	&__body {
		@media (max-width: 1089px) {
			position: fixed;
			top: 0;
			right: -100%;
			width: 50%;
			height: 100%;
			background-color: $color-white;
			transition: right .3s ease 0s;
			z-index: 4;
		}
	}

	&__body.active {
		right: 0;
	}

	&__list > li {
		position: relative;

		@media (max-width: 1089px) {
			margin-bottom: 40px;
		}
	}

	&__list > li:last-child {
		margin-bottom: 0;
	}

	&__list {
		list-style-type: none;
		padding: 0;

		@media (min-width: 1089px) {
			display: flex;
			align-items: center;
		}

		@media (max-width: 1089px) {
			margin-top: 100px;
			text-align: center;
		}
	}

	&__link {
		font-size: $medium-font;
		line-height: 30px;
		color: $color-dark;
		margin: 0 15px;
		padding: 5px 0;
		cursor: pointer;
		transition: border-bottom-color .25s ease;
		border-bottom: 2px solid transparent;

		&:hover {
			border-bottom-color: $color-dark;
		}

		@media (max-width: 1089px) {
			font-size: $base-font;
			line-height: 32px;
			margin: 0 auto;

			&:hover {
				background: none;
				color: $color-dark;
				border-bottom: 2px solid $color-blue;
				padding: 6px 13px;
			}
		}
	}
}
