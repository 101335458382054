@import "@styles/variables.scss";

.pricing {
	width: 100%;
	background: $color-blue;
	color: $color-light;
	height: 522px;
	position: relative;
	margin-top: 170px;
	display: flex;
	justify-content: center;

	&__iguana {
		position: absolute;
		top: -130px;
		left: 20%;
		z-index: 10;
	}

	&__text {
		position: absolute;
		top: 175px;
		left: $base-margin;
		width: 363px;
	}

	@media (max-width: $width-laptop--lg) {
		height: 860px;

		&__iguana {
			position: absolute;
			top: -130px;
			left: unset;
			right: 2%;
			z-index: 10;
		}
	}

	@media (max-width: 600px) {
		&__iguana {
			width: 80%;
			top: -10%;
		}

		&__text {
			top: 25%;
			max-width: 90%;
		}

		&__money {
			width: 40px;
		}
	}
}
