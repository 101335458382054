@import "@styles/variables.scss";

.chain {
	margin: 0 $base-margin;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__text {
		width: 612px;
		display: flex;
		flex-direction: column;
		gap: 35px;
	}

	&__title {
		margin-bottom: 12px;
	}

	&__tab {
		display: grid;
		grid-template-columns: 17px 1fr;
		align-items: center;
		column-gap: 12px;
	}

	&__subtitle {
		margin-bottom: 8px;
	}

	&__paragraph {
		grid-column: 2 / -1;
	}

	@media (max-width: 1240px) {
		flex-direction: column-reverse;
		row-gap: 40px;
		margin: 0 auto;

		&__text {
			width: $base-width;
		}

		&__group-image {
			width: 100%;
		}
	}

	@media (max-width: 768px) {
		width: 100%;

		&__group-image {
			width: 91%;
		}
	}

	@media (max-width: 712px) {
		width: 100%;

		&__title {
			display: none;
		}

		&__tab {
			display: grid;
			grid-template-columns: 17px 1fr;
			align-items: center;
			column-gap: 12px;
		}

		&__text {
			margin-top: 31px;
			width: 90%;
			padding: 0 10px;
			gap: 10px;
		}

		&__subtitle {
			margin-bottom: 5px;
		}

		&__paragraph {
			grid-column: 1 / -1;
		}
	}
}
