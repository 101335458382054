@import "@styles/variables.scss";

$shadow: rgba(0 0 0 / 25%);

.welcome-screen {
	background: $color-blue;
	background-image: url("../image/welcome/circles.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-radius: 10px;

	color: $color-light;

	height: 850px;
	max-width: 1880px;

	display: flex;
	flex-direction: column;
	align-items: center;

	position: relative;
	margin: 0 auto 20px;

	&__text-block {
		position: absolute;
		top: 20%;
		transform: translateY(-20%);
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 25px;
		margin: 0 40px;
		padding-bottom: 30px;
    max-width: 60em;
	}

	&__title {
		font-size: $large-title-font;
		font-weight: normal;
		text-align: center;
	}

	&__browser {
		position: absolute;
		bottom: 0;
		right: 336px;
	}

	&__messages {
		position: absolute;
		bottom: 15%;
		right: 1290px;

		display: flex;
		flex-direction: column;
		row-gap: 20px;
		width: 350px;

		img {
			box-shadow: 0 4px 50px $shadow;
			border-radius: 12px;
		}
	}

	&__message-small {
		width: 80%;
		align-self: flex-end;
	}

	&__message-big {
		width: 100%;
		align-self: start;
	}

	&__friends {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	@media screen and (max-width: 1800px){
		border-radius: 0;
		margin: 0 auto;
	}

	@media screen and (max-width: 912px){
		&__friends {
			height: 45%;
		}

		&__browser {
			height: 38%;
		}
	}

	@media screen and (max-width: 680px){
		&__title {
			font-size: $medium-title-font;
		}
	}

	@media (max-width: $width-mobile--lg) {
		height: 475px;

		&__text-block {
			position: absolute;
			top: 30%;
			row-gap: 15px;
			transform: translateY(-30%);
			margin: 0 20px;
			max-width: 90%;
		}

		&__title {
			font-size: $large-font;
		}
		
		&-btn {
			margin: 13px 0;
		}
		
		&__friends {
			height: 40%;
		}

		&__browser {
			height: 30%;
			right: 150px;
		}
	}

	@media (max-width: 450px) {
		&__text-block {
			top: 20%;
			transform: translateY(-20%);
		}
	}
}