@import "@styles/variables.scss";

.dashboards {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	margin: 0 $base-margin;

	&__tab {
		width: 594px;
		display: grid;
		grid-template-columns: 17px 1fr;
		align-items: center;
		column-gap: 12px;
	}

	&__paragraph {
		grid-column: 2 / -1;
		margin-bottom: 19px;
	}

	&__social-btn {
		display: flex;
		grid-column: 2 / -1;
	}

	.card {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__image {
		width: 70%;
	}

	&__bottom-image {
		margin-right: -100px;
	}

	@media (max-width: 1240px) {
		flex-direction: column;
		row-gap: 30px;
		margin: 0 auto;

		.card {
			width: $base-width;
			align-items: flex-start;
			margin: 0 auto;
		}

		&__top-card {
			.dashboards-tab {
				margin-top: -50px;
			}
		}

		&__bottom-card {
			width: 100%;
		}

		&__tab {
			width: 90%;
		}

		&__image {
			width: 100%;
		}

		&__top-card {
			display: flex;
			flex-direction: column;
			margin-bottom: 40px;
		}

		&__bottom-card {
			display: flex;
			flex-direction: column-reverse;
		}
	}

	@media (max-width: $width-mobile--lg) {
		.card {
			width: 100%;
			align-items: flex-start;
			margin: 0 auto;
		}

		&__tab {
			width: $base-width;
			margin: 0 auto;
		}

		&__top-card {
			.dashboards__tab {
				margin-top: 0;
			}
		}
	}

	@media (max-width: 395px) {
		&__social-btn {
			grid-column: 1 / -1;
		}
	}
}

.social {
	display: flex;
	border: 1px solid #000;
	border-radius: 5px;
	margin-right: 10px;
	cursor: pointer;

	&__icon {
		margin: 7px 8px 7px 14px;
	}

	&__icon-app {
		margin: 7px 8px 7px 0;
		padding-right: 8px;
	}

	@media (max-width: 335px) {
		width: 134px;

		&__icon {
			width: 21px;
		}

		&__icon-app {
			width: 79px;
		}
	}
}
