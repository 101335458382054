@import "@styles/variables.scss";

.ashes {
	background: #f3f6fc;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	column-gap: $base-margin;

	&__image {
		margin-left: $base-margin;
	}

	&__text {
		width: $base-width;
		display: flex;
		flex-direction: column;
		gap: 25px;
		margin: 124px 61px 108px 0;
	}

	@media (max-width: 1296px) {
		&__image {
			margin-left: 0;
		}

		&__text {
			margin: 105px 4% 100px 0;
		}
	}

	@media (max-width: 1200px) {
		flex-direction: column;
		padding: 105px 0 100px;

		&__text {
			gap: 14px;
			margin: 69px 0 0;
		}
	}

	@media (max-width: 768px) {
		&__image {
			width: 67%;
		}
	}

	@media (max-width: $width-mobile) {
		padding: 59px 0 63px;

		&__image {
			width: 91%;
		}
	}
}
