@import "@styles/variables.scss";

.stages {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 110px;
	row-gap: 66px;
	max-width: $base-width;
	margin: 0 $base-margin;
	padding: 143px 0 173px;

	&__subtitle {
		margin-bottom: 12px;
	}

	&__icon {
		margin-left: -25px;
	}

	&__card {
		padding-left: 25px;
	}

	@media (max-width: 1568px) {
		margin: 0 40px;
		column-gap: 50px;
	}

	@media (max-width: 1200px) {
		grid-template-columns: repeat(2, 1fr);
		padding: 121px 0 115px;
		margin: 0 40px;
		column-gap: 30px;
		row-gap: 45px;
	}

	@media (max-width: 580px) {
		grid-template-columns: repeat(1, 1fr);
		padding: 80px 0;
		margin: 0 auto;
		column-gap: 0;
		row-gap: 30px;
		width: $base-width;

		&__card {
			padding-left: 0;
		}

		&__icon {
			width: 40px;
			margin-left: 0;
		}
	}
}
