$font-family-primary: "Manrope", sans-serif;

$color-blue: #508aeb;
$color-white: #fff;
$color-dark: #001a45;
$color-red: #ff6d6d;
$color-grey: #7a89a6;
$color-light: #f5f5f5;

$xxs-font: 13px;
$xs-font: 15px;
$sm-font: 16px;
$base-font: 18px;
$medium-font: 22px;
$large-font: 28px;
$xlarge-font: 36px;
$small-title-font: 48px;
$medium-title-font: 50px;
$large-title-font: 65px;

$base-width: 88%;
$base-margin: 6%;

$width-mobile: 320px;
$width-mobile--md: 480px;
$width-mobile--lg: 550px;
$width-tablet: 900px;
$width-laptop--sm: 1024px;
$width-laptop--md: 1440px;
$width-laptop--lg: 1600px;